import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import 'moment/dist/locale/en-gb';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import App from './app';
import ReactDOM from 'react-dom';
import MaintenanceGuard from './components/shared/MaintenanceGuard';
import RoutesGuard from './components/shared/RoutesGuard';
import UserAgentWarning from './components/shared/components/UserAgentWarning';
import './globalDeclarations';
import './index.css';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import { GoogleMapsProvider } from './utils/GoogleMapsProvider';

const nonLibraryPages = ['notificationbase'];

moment().locale('en-gb');
window.moment = moment;

const currentRoute = window.location.pathname;
let loadLibraries = true;

for (const page of nonLibraryPages) {
  if (currentRoute.search(page) !== -1) {
    loadLibraries = false;
  }
}

if (loadLibraries) {
  window.google?.charts?.load('current', {
    packages: ['corechart', 'bar', 'geochart'],
    mapsApiKey: `${process.env.VITE_GOOGLE_API}`,
  });
}

const appHeight = () => {
  document.documentElement.style.setProperty(
    '--app-height',
    `${window.innerHeight}px`
  );
};
window.addEventListener('resize', appHeight);
appHeight();

// Outer myRotra container
ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserAgentWarning />
        <Router>
          <MaintenanceGuard>
            <GoogleMapsProvider>
              <QueryParamProvider ReactRouterRoute={Route}>
                <RoutesGuard>
                  <HelmetProvider>
                    <App />
                  </HelmetProvider>
                </RoutesGuard>
              </QueryParamProvider>
            </GoogleMapsProvider>
          </MaintenanceGuard>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.querySelector('#root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
